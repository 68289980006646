// extracted by mini-css-extract-plugin
export var add = "kg_y0";
export var add_addBtn = "kg_J0";
export var add_btn = "kg_H0";
export var add_descrWrapper = "kg_G0";
export var add_header = "kg_B0";
export var add_headerWrapper = "kg_z0";
export var add_imageBtn = "kg_F0";
export var add_list = "kg_D0";
export var add_pic = "kg_C0";
export var add_removeBtn = "kg_K0";