import React, { useRef, useState } from 'react'
import {
  Button,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  FormControl,
} from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import { useTranslation } from 'react-i18next'
import Icon from '~components/Icon'
import Layout from '~components/Layout'
import S from '~components/seo'
import cn from 'classnames'
import useHttp from '~hooks/useHttp'
import useAuth from '~hooks/useAuth'
import * as s from './Add.module.scss'
import { CATEGORIES, LOCATIONS } from './constants'

const Add = () => {
  const { request } = useHttp()
  const { userId } = useAuth()
  const inputFile = useRef(null)
  const [card, setCard] = useState({
    location: 'ukraine',
    pics: [],
  })
  const { t } = useTranslation()

  const handleRemovePhoto = (i) => {
    setCard((prev) => {
      const newArr = [...prev.pics]
      newArr.splice(i, 1)
      return { ...prev, pics: [...newArr] }
    })
  }

  const handleCategory = (type) => {
    setCard((prev) => ({ ...prev, type }))
  }

  const handleLocation = (location) => {
    setCard((prev) => ({ ...prev, location }))
  }

  const handleTitle = (e) => {
    setCard((prev) => ({ ...prev, title: e.target.value }))
  }

  const handlePic = (pic) => {
    setCard((prev) => {
      const newArr = [...prev.pics.filter((el) => !!el)]
      return { ...prev, pics: [...newArr, pic] }
    })
  }

  const handleDescription = (e) => {
    setCard((prev) => ({ ...prev, descr: e.target.value }))
  }

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click()
  }

  const onPhotoUpload = async () => {
    const file = inputFile.current.files[0]
    const data = new FormData()
    data.append('file', file)
    data.append('upload_preset', 'RefHelp_cards')

    const res = await fetch(
      'https://api.cloudinary.com/v1_1/didw9zkq6/image/upload',
      {
        method: 'POST',
        body: data,
      }
    )

    const imgFile = await res.json()

    handlePic(imgFile.secure_url)
  }

  const handleAddCard = async () => {
    try {
      await request('https://refhelpapp.herokuapp.com/card/add', 'POST', {
        ...card,
        userId,
      })
      window.location.href = '/'
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Layout>
      <S title={t(`titles.add`)} />
      <Container as="section" className={s.add}>
        <div className={s.add_headerWrapper}>
          <div className={s.add_header}>
            <Dropdown className="card">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {t(`categories.title`)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {CATEGORIES.map((el) => (
                  <DropdownItem
                    className={cn({ active: card.type === el })}
                    onClick={() => {
                      handleCategory(el)
                    }}
                  >
                    {t(`categories.${el}`)}
                  </DropdownItem>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div className={s.add_pic}>
              {card.type && <Icon name={card.type} size={35} />}
            </div>
            <Dropdown className="card">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {t(`locations.${card.location}`)}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {LOCATIONS.map((el) => (
                  <DropdownItem
                    className={cn({ active: card.location === el })}
                    onClick={() => {
                      handleLocation(el)
                    }}
                  >
                    {t(`locations.${el}`)}
                  </DropdownItem>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Form className="form white center">
            <FloatingLabel controlId="floatingInput" label={t(`form.cardName`)}>
              <FormControl
                onChange={handleTitle}
                className="card"
                autoComplete="off"
                type="text"
                placeholder="name"
              />
            </FloatingLabel>
          </Form>
        </div>
        <div className={s.add_list}>
          {[...Array(5)].map((_, i) => (
            <>
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`p-${i}`}
                className={cn('btn-success', s.add_imageBtn)}
              >
                {card.pics[i] ? (
                  <div>
                    <img src={card.pics[i]} alt={`card-${i + 1}`} />
                    <button
                      type="button"
                      aria-label="remove photo"
                      onClick={() => handleRemovePhoto(i)}
                      className={s.add_removeBtn}
                    />
                  </div>
                ) : (
                  <button
                    type="button"
                    aria-label="add photo"
                    onClick={onButtonClick}
                    className={s.add_addBtn}
                  >
                    <Icon name="photo" size={70} />
                  </button>
                )}
              </div>
              <input
                onChange={onPhotoUpload}
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
              />
            </>
          ))}
        </div>
        <div className={s.add_descrWrapper}>
          <Form className="form white">
            <FormControl
              onChange={handleDescription}
              as="textArea"
              className="card"
              autoComplete="off"
              type="text"
              placeholder={t(`form.descr`)}
            />
          </Form>
        </div>
        <Button onClick={handleAddCard} className={s.add_btn} variant="primary">
          {t(`form.addBtn`)}
        </Button>
      </Container>
    </Layout>
  )
}

export default Add
